<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <UserCard :dafultName="userName"></UserCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <Banner></Banner>
        <div class="mt-4 settingsBody rounded card">
          <div class="pt-3 pb-0 ps-4">
            <strong>Datos Básicos</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendBasic">
              <div class="mb-3">
                <label for="name" class="form-label"
                  >Nombre o Razón Social</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  v-model="userName"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="ID" class="form-label">NIT o Cédula</label>
                <input
                  type="number"
                  class="form-control"
                  name="ID"
                  v-model="userID"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Correo Electrónico</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="userEmail"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="mobile" class="form-label">Número Celular</label>
                <input
                  type="number"
                  class="form-control"
                  name="mobile"
                  v-model="userMobile"
                />
              </div>
              <div class="mb-3">
                <label for="website" class="form-label">Sitio Web</label>
                <input
                  type="url"
                  class="form-control"
                  name="website"
                  v-model="userWebsite"
                />
              </div>
              <div class="mb-3">
                <label for="country" class="form-label">País</label>
                <select
                  class="form-select text-capitalize"
                  name="country"
                  disabled
                >
                  <option
                    v-for="country in countryList"
                    v-bind:value="country._id"
                    :key="country.name"
                    >{{ country.name }}
                  </option>
                </select>
              </div>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import Banner from "@/components/layout/Banner";
import UserCard from "@/views/settings/UserCard";

import Storage from "@/modules/storage";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: { Legal, Banner, UserCard },
  name: "Settings Basic",
  data() {
    return {
      userName: "",
      userID: "",
      userEmail: "",
      userMobile: "",
      userWebsite: "",
      userCountry: "",
      countryList: []
    };
  },
  beforeCreate() {
    localStorage.setItem("currentLocation", "Ajustes / Datos Básicos");
  },
  methods: {
    sendBasic() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      let emailFlag = false;
      const URL =
        new Storage().baseURL +
        "/auth/user/" +
        localStorage.getItem("user._id");
      const sendData = [
        {
          name: this.userName,
          ID: this.userID
        }
      ];
      if (this.userMobile !== "") {
        sendData[0].mobile = this.userMobile;
      } else {
        sendData[0].mobile = null;
      }
      if (this.userWebsite !== "") {
        sendData[0].website = this.userWebsite;
      } else {
        sendData[0].website = null;
      }
      if (
        this.userEmail !== "" &&
        this.userEmail !== localStorage.getItem("user.email")
      ) {
        sendData[0].email = this.userEmail;
        emailFlag = true;
      }
      axios
        .put(URL, sendData, {
          withCredentials: true
        })
        .then(response => {
          localStorage.setItem("user.name", response.data.body[0].name);
          localStorage.setItem("user.ID", response.data.body[0].ID);
          localStorage.setItem("user.mobile", response.data.body[0].mobile);
          localStorage.setItem("user.website", response.data.body[0].website);
          if (emailFlag) {
            Swal.fire({
              icon: "success",
              title: "Datos Actualizados",
              text:
                "Por favor verifique su bandeja de correo electrónico para hacer el cambio de Email"
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Datos Actualizados",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
          }
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: "No es posible actualizar los datos en este momento",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        });
    }
  },
  mounted() {
    document.getElementById("userOptions").style.display = "none";

    const store = new Storage();

    this.userName = localStorage.getItem("user.name");
    if (localStorage.getItem("user.ID") !== "null") {
      this.userID = parseInt(localStorage.getItem("user.ID"));
    }
    this.userEmail = localStorage.getItem("user.email");
    if (localStorage.getItem("user.mobile") !== "null") {
      this.userMobile = parseInt(localStorage.getItem("user.mobile"));
    }
    if (localStorage.getItem("user.website") !== "null") {
      this.userWebsite = localStorage.getItem("user.website");
    }

    store.getData("country").then(data => {
      this.countryList = [];
      data.forEach(country => {
        this.countryList.push(country);
        if (country._id === localStorage.getItem("user.country")) {
          this.userCountry = country._id;
        }
      });
    });
  }
};
</script>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>
